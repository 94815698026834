import { types } from 'mobx-state-tree';

export const AduPed = types.model(
  'AduPed',
  {
    Adult: types.maybeNull(types.number),
    Pediatric: types.maybeNull(types.number),
  }
);

export const ResearchStaffModel = types.model(
  'ResearchStaffModel',
  {
    TeamManagers: AduPed,
    ResearchCoordinators: AduPed,
    AdminAssistants: AduPed,
    ProjectLeaders: AduPed,
    Volunteers: AduPed,
    Statisticians: AduPed,
    Total: AduPed,
  }
);

export const BedsModel = types.model(
  'BedsModel',
  {
    IntensiveCare: AduPed,
    EmergencyRoom: AduPed,
    AcuteCare: AduPed,
    Total: AduPed,
  });

export const SiteDetails = types.model(
  'SiteDetails',
  {
    Population: types.frozen({ Adult: false, Pediatric: false }),
    Beds: BedsModel,
    IntensiveCareStaff: ResearchStaffModel,
    EmergencyRoomStaff: ResearchStaffModel,
    AcuteCareStaff: ResearchStaffModel,
    Capacity: types.frozen({
      OffHoursRandomization: false,
      Pharmacy: types.frozen({AvailableOnSite: false, AvailableForTrialsOtherwise: false})
    }),
  }
);

const DepartmentStaffModel = types.model(
  'DepartmentStaffModel',
  {
    NbAdultICU: types.maybeNull(types.number),
    NbAdultAcute: types.maybeNull(types.number),
    NbAdultER: types.maybeNull(types.number),
    NbAdultCentralized: types.maybeNull(types.number),
    NbPediatricICU: types.maybeNull(types.number),
    NbPediatricAcute: types.maybeNull(types.number),
    NbPediatricER: types.maybeNull(types.number),
    NbPediatricCentralized: types.maybeNull(types.number),
    LastModifiedDate: types.maybeNull(types.number),
    DateOfInformation: types.maybeNull(types.number)
  }
);

export const CoordinatesModel = types.model(
  'CoordinatesModel',
  {
    lat: 0,
    lng: 0,
  }
);

export const SiteModel = types.snapshotProcessor(types.model(
  'SiteModel',
  {
    siteId: '',
    Coordinates: CoordinatesModel,
    International: false,
    HospitalType: '',
    PostalCode: '',
    SiteName: '',
    ParticipatesInStudies: types.boolean,
    SiteDetails: types.maybeNull(SiteDetails),
    Population: types.frozen({ Adult: false, Pediatric: false }),
    Beds: BedsModel,
    DepartmentStaff: DepartmentStaffModel,
    Capacity: types.frozen({
      OffHoursRandomization: false,
      Pharmacy: types.frozen({AvailableOnSite: false, AvailableForTrialsOtherwise: false})
    }),
    ParticipatingStudyList: types.array(types.number),
    LatestInformationDate: types.string,
  }
),{
  preProcessor(sn) {
    return {
      ...sn,
      siteId: sn.SiteName.replace(' ', '').toLowerCase(),
      LatestInformationDate: sn.LatestInformationDate.split('T')[0],
    };
  }
});

const SiteModelWrapper = types.model(
  'SiteModel',
  {
    Item: types.array(types.maybeNull(SiteModel)),
  }
);

export default SiteModelWrapper;

