export const ROUTES = {
  PUBLICATIONS: {
    id: 'PUBLICATIONS',
    name: 'PUBLICATIONS',
    i18nId: 'publications:menu-label',
    displayInMenu: false,
  },
};

export const { PUBLICATIONS: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
