import { map, round } from "lodash";
import { types } from "mobx-state-tree";

const Site = types.model(
    'Site',
    {
        SiteRank: 0,
        TotalPatients: 0,
        RunningTotal: 0,
        FiftyPctPassed: false,
        NinetyPctPassed: false,
    }
);

const Stats = types.model(
    'Stats',
    {
        Average: 0,
        Minimum: 0,
        Maximum: 0,
        Total: 0,
        NbSitesInStudies: 0,
        TotalSites: 0,
        Median: 0,
        FiftyPercent: 0,
        NinetyPercent: 0,
        SiteRankAtFiftyPct: 0,
        SiteRankAtNinetyPct: 0,
    }
);

const PatientsPerSiteModel = types.model(
    'PatientsPerSiteModel',
    {
        Stats: types.maybeNull(Stats),
        SiteNumbers: types.array(Site),
    }
);

const PatientsPerSiteModelWrapper = types.snapshotProcessor(
    types.model(
        'PatientsPerSiteModelWrapper',
        {
            Item: types.maybeNull(PatientsPerSiteModel),
        }
    ), {
        preProcessor(sn) {
            return {
                Item: {
                    Stats: {
                        ...sn.Item.Stats,
                        Average: round(sn.Item.Stats.Average, 2),
                        Median: round(sn.Item.Stats.Median, 2),
                    },
                    SiteNumbers: map(sn.Item.SiteNumbers, (site) => ({
                        ...site,
                        TotalPatients: Math.floor(site.TotalPatients),
                        RunningTotal: Math.floor(site.RunningTotal),
                    })),
                }
            }
        }
    }
);

export default PatientsPerSiteModelWrapper;
