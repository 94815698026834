/* eslint-disable no-param-reassign */

import { each } from 'lodash';
import { types } from 'mobx-state-tree';

const storeBuilder = (storeConfig) => {
  const {
    modelName,
    models,
    modelStructure,
  } = storeConfig;

  let baseModel = types.model();
  if (models) {
    each(models, model => {
      baseModel = types.compose(baseModel, model);
    });
  }

  return types.compose(baseModel, types.model({ ...modelStructure }))
    .actions(self => ({
      setStoreAttribute(key, value) {
        if (self[key] !== undefined) {
          self[key] = value;
        } else {
          // eslint-disable-next-line no-console
          console.warn('TRYING TO SET ILLEGAL KEY: ', key);
        }
      },
    }))
    .named(modelName);
};

export default storeBuilder;