import { APP_NAME, INFO_EMAIL_LINK } from '../../constants';

const moduleRoot = `${ APP_NAME }/contact`;

export const PATHS = {
  CONTACT: {
    path: `${moduleRoot}`,
    hardPath: `${INFO_EMAIL_LINK}?subject=General inquiry from CCRN website`,
  },
  CONTACT_LINK: {
    path: `${moduleRoot}`,
    hardPath: `${INFO_EMAIL_LINK}?subject=General inquiry from CCRN website`,
  },
  U_DE_S: {
    path: 'https://www.usherbrooke.ca/',
  },
};

export default PATHS;
