import { types } from 'mobx-state-tree';

const InterventionModel = types.model(
  'InterventionModel',
  {
    TypeName: '',
    TypeCode: '',
    Name: '',
  }
);

export default InterventionModel;
