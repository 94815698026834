import { types } from 'mobx-state-tree';

const CountryModel = types.model(
    'Country',
    {
        Name: "",
        Code: "",
        NbSites: 0,
    }
);

export default CountryModel;
