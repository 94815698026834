import { types } from 'mobx-state-tree';

export const WindowDatesModel = types.model(
  'WindowDatesModel',
  {
    MinYear: types.integer,
    MinMonth: types.integer,
    MaxYear: types.integer,
    MaxMonth: types.integer,
    MinValue: types.maybeNull(types.number),
    MaxValue: types.maybeNull(types.number),
  }
);

const WindowDatesModelWrapper = types.snapshotProcessor(
  types.model(
    'WindowDatesModelWrapper',
    {
      Item: types.maybeNull(WindowDatesModel),
    }
  ), {
    preProcessor(sn) {
      if (!sn?.Item)
        return sn;

      return {
        Item: {
          ...sn.Item,
          MinValue: sn.Item.MinYear * 12 + sn.Item.MinMonth,
          MaxValue: sn.Item.MaxYear * 12 + sn.Item.MaxMonth,
        },
      }
    }
  }
);

export default WindowDatesModelWrapper;
