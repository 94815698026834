import { APP_NAME } from '../../constants';

const moduleRoot = `${ APP_NAME }/ccrn`;

export const PATHS = {
  CCRN: { path: `${ moduleRoot }` },
  VISION: { path: `${ moduleRoot }/vision` },
  MISSION: { path: `${ moduleRoot }/mission` },
  CORE_VALUES: { path: `${ moduleRoot }/core-values` },
  FOCUS_AREAS: { path: `${ moduleRoot }/focus-areas` },
  TEAM: { path: `${ moduleRoot }/team` },
};

export default PATHS;
