import { types } from 'mobx-state-tree';

const PublicationModel = types.model(
  'PublicationModel',
  {
    typeId: "",
    id: "",
    title: "",
    link: "",
    summary: "",
    citationCount: types.maybeNull(types.integer),    //nullable int
    economicImpact: types.maybeNull(types.boolean),   //nullable bool, true = Yes, false = No, null = no answer
    citationImpact: types.maybeNull(types.number),    //nullable double
    lastScopusUpdate: types.maybeNull(types.string),  //nullable date
  }
);

const StudyPublicationsModel = types.model(
  'StudyPublicationsModel',
  {
    studyId: types.integer,
    studyName: "",
    studyShortName: "",
    publications: types.array(PublicationModel)
  }
);

const PublicationModelWrapper = types.model(
  'PublicationModelWrapper',
  {
    Item: types.array(types.maybeNull(StudyPublicationsModel)),
  }
);

export default PublicationModelWrapper;
