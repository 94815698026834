import { types } from 'mobx-state-tree';
import { CoordinatesModel } from './site-model';

const StudyModel = types.model(
  'StudyModel',
  {
    Id: types.number,
    ShortName: "",
    Status: types.number,   // 982 = Ongoing, 983 = Complete, 1910 =  Not started
    IsOngoing: types.boolean,
    IsCompleted: types.boolean,
    IsNotStarted: types.boolean,
  }
);

const StudiesInfoModel = types.model(
  'StudiesInfoModel',
  {
    StudyList: types.maybeNull(types.array(StudyModel)), 
    Count: 0,            // Number of associated studies. If = 0, does not participate in any filtered studies
    TotalPatients: 0     // Total according to filters
  }
);

const CoordinatingCenterModel = types.model(
  'CoordinatingCenterModel',
  {
    CoordinatingCenterId: types.number,
    Name: "",
    Address: "",
    PostalCode: "",
    Country: "",
    Province: "",
    Coordinates: types.maybeNull(CoordinatesModel),
    AllStudies: types.maybeNull(StudiesInfoModel),
    FilteredStudies: types.maybeNull(StudiesInfoModel),
  }
);

const CoordinatingCenterModelWrapper = types.model(
  'CoordinatingCenterModelWrapper',
  {
    Item: types.array(types.maybeNull(CoordinatingCenterModel))
  }
);

export default CoordinatingCenterModelWrapper;
