/* eslint-disable no-console */
import axios from 'axios';
import { isUndefined } from 'lodash';
import Cookies from 'js-cookie';

import { BACKEND_SERVER } from './endpoints';
import ApplicationStore from '../stores/application-store';
const APPLICATION_JSON = 'application/json';

const controller = {};
export const fetchData = async (fetchOptions) => {
  const {
    endpoint,
    formParams,
  } = fetchOptions;

  if (isUndefined(endpoint)) {
    /* eslint-disable-next-line id-denylist */
    return { data: 'NO_DATA' };
  }

  const {
    api,
    url,
    method = 'GET',
    formData = () => {},
  } = endpoint;

  const hardTarget = ApplicationStore?.credTarget;
  const explicitUrl = api === 'hard' ? `${ hardTarget }${ url() }` : `${ BACKEND_SERVER }${ api }${ url(formParams) }`;

  const headers = {
    Accept: APPLICATION_JSON,
    "Content-Type": APPLICATION_JSON,
    Authorization: api === 'hard' ? Cookies.get('authKey') || null : null,
  };

  if (controller[explicitUrl]) {
    controller[explicitUrl].abort();
    console.log(`The previous call to ${explicitUrl} was aborted`);
  }

  controller[explicitUrl] = new AbortController();
  const config = {
    method,
    url: explicitUrl,
    headers,
    data: {
      ...formData(formParams),
      apiKey: ApplicationStore?.credKey,
      cultureName: Cookies.get('user_locale') || 'en-CA',
    },
    signal: controller[explicitUrl]?.signal,
  };

  return axios(config)
    .then((results) => {
      const data = results.data;

      controller[explicitUrl] = null;
      if (!isUndefined(data.payload)){
        return JSON.parse(atob(data.payload));
      }
      return data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { canceled: true };
      }

      throw(error);
    });
};

export default fetchData;
