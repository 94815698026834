import { lazy } from 'react';

const Account = lazy(() => import('../../components/modules/account/account'));
const Login = lazy(() => import('../../components/modules/account/login/login'));
const ForgotPassword = lazy(() => import('../../components/modules/account/forgot-password/forgot-password'));
const ResetPassword = lazy(() => import('../../components/modules/account/reset-password/reset-password'));

export const COMPONENTS = {
  ACCOUNT: Account,
  LOGIN: Login,
  FORGOT_PASSWORD: ForgotPassword,
  CREATE_ACCOUNT: Account,
  RESET_PASSWORD: ResetPassword,
  SITE_PERMISSIONS: Account,
};

export default COMPONENTS;
