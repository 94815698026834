export const EN_CA = 'en-CA';
export const FR_CA = 'fr-CA';

/*
*
* The first language specified
* in this list will be used as THE
* default locale throughout the
* application
*
*/
export const supportedLanguages = [
  EN_CA,
  FR_CA,
];
