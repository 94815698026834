/* eslint-disable-next-line no-shadow */
export const Resolution = {
  XSMALL: 'XSMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  XLARGE: 'XLARGE',
  XXLARGE: 'XXLARGE',
}

