import { createTheme } from '@material-ui/core';
import { colors } from '@material-ui/core';

const buttonPaddingSmall = '0.57em 1.15em';
const buttonPaddingMedium = '0.75em 1em';
const buttonPaddingLarge = '1em 1.5em';

const tooltipStyle = {
  // backgroundColor: 'rgba(218, 218, 218, 0.95)',
  backgroundColor: 'rgba(174, 176, 185, 0.95)',  // #AEB0B7
  fontSize: '1rem',
  fontWeight: '200',
  // color: '#505D68',
  color: 'white',
}

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: tooltipStyle,
      arrow: {
        ...tooltipStyle,
        color: tooltipStyle.backgroundColor,
        backgroundColor: 'rgba(255,255,255, 0.1)',
      },
    },
    MuiTab: {
      root: {
        maxWidth: '500px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        fontSize: '1em',
        height: '2.5em',
        padding: buttonPaddingMedium,
        whiteSpace: 'nowrap',
      },
      sizeSmall: {
        fontSize: '0.875em',
        height: '2.29em',
        padding: buttonPaddingSmall,
      },
      sizeLarge: {
        fontSize: '1em',
        height: '3.5em',
        padding: buttonPaddingLarge,
        lineHeight: '1.5em',
        fontWeight: 700,
      },
      contained: {
        boxShadow: 'none',
        "&:hover": {
          boxShadow: 'none',
        },
      },
      text: {
        padding: buttonPaddingMedium,
      },
      textSizeSmall: {
        padding: buttonPaddingSmall,
      },
      textSizeLarge: {
        padding: buttonPaddingLarge,
      },
      outlined: {
        padding: buttonPaddingMedium,
      },
      outlinedSizeSmall: {
        padding: buttonPaddingSmall,
      },
      outlinedSizeLarge: {
        padding: buttonPaddingLarge,
      },
      endIcon: {
        marginLeft: '0.5em'
      }
    },
    MuiCheckbox: {
      root: {
        colorPrimary: {
          color: 'black',
        },
        colorSecondary: {
          color: 'blue',
        },

        // '&$checked': {
        //   color: 'yellow',
        // },
      },
    },
  //   MuiFormControl: {
  //     root: {
  //       backgroundColor: ccrnWhite,
  //     },
  //   },
  //   MuiOutlinedInput: {
  //     root: {
  //       borderRadius: '2px',
  //     },
  //   },
  //   MuiFormLabel: {
  //     root: {
  //       fontWeight: 700,
  //     },
  //   },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    button: {
      fontFamily: 'Noto, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      dark: colors.red[800],
      main: '#B30013', //colors.red[500],
      light: colors.indigo[100],
      contrastText: '#E4E4E4',
    },
    // secondary: {
    //   main: ccrnRedMed,
    //   contrastText: ccrnWhite,
    // }
  },

});

export default theme;
