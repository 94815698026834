import { APP_NAME } from '../../constants';

const moduleRoot = `${ APP_NAME }/publications`;

export const PATHS = {
  PUBLICATIONS: {
    path: `${ moduleRoot }`,
  },
};

export default PATHS;
