import { types } from 'mobx-state-tree';

const VisibleLocationModel = types.model(
  'VisibleLocationModel',
  {
    id: "",
    SiteName: "",
    markerType: "",
  }
);

export default VisibleLocationModel;
