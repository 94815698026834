import { APP_NAME } from '../../constants';

const moduleRoot = `${ APP_NAME }/`;

export const PATHS = {
  HOME: {
    path: `${ moduleRoot }home`,
  },
  LANDING: {
    path: `${ moduleRoot }landing`,
  },
};

export default PATHS;
