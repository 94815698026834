import {
  DEFAULT_ROUTE as RouteHomeDefault,
  ROUTES as RoutesHome,
} from './home/routes';
import { PATHS as PathsHome } from './home/paths';
import { COMPONENTS as ComponentsHome } from './home/components';

import {
  DEFAULT_ROUTE as RouteCcrnDefault,
  ROUTES as RoutesCcrn,
} from './ccrn/routes';
import { PATHS as PathsCcrn } from './ccrn/paths';
import { COMPONENTS as ComponentsCcrn } from './ccrn/components';

import {
  DEFAULT_ROUTE as RoutePublicationsDefault,
  ROUTES as RoutesPublications,
} from './publications/routes';
import { PATHS as PathsPublications } from './publications/paths';
import { COMPONENTS as ComponentsPublications } from './publications/components';

import {
  DEFAULT_ROUTE as RouteReportsDefault,
  ROUTES as RoutesReports,
} from './reports/routes';
import { PATHS as PathsReports } from './reports/paths';
import { COMPONENTS as ComponentsReports } from './reports/components';

import {
  DEFAULT_ROUTE as RouteResearchPortfolioDefault,
  ROUTES as RoutesResearchPortfolio,
} from './research-portfolio/routes';
import { PATHS as PathsResearchPortfolio } from './research-portfolio/paths';
import { COMPONENTS as ComponentsResearchPortfolio } from './research-portfolio/components';

import {
  DEFAULT_ROUTE as RouteContactDefault,
  ROUTES as RoutesContact,
} from './contact/routes';
import { PATHS as PathsContact } from './contact/paths';
import { COMPONENTS as ComponentsContact } from './contact/components';

import {
  DEFAULT_ROUTE as RouteGeneralDefault,
  ROUTES as RoutesGeneral,
} from './general/routes';
import { PATHS as PathsGeneral } from './general/paths';
import { COMPONENTS as ComponentsGeneral } from './general/components';

import {
  DEFAULT_ROUTE as RouteAccountDefault,
  ROUTES as RoutesAccount,
} from './account/routes';
import { PATHS as PathsAccount } from './account/paths';
import { COMPONENTS as ComponentsAccount } from './account/components';

export const MODULES = {
  HOME: {
    id: 'HOME',
    routes: RoutesHome,
    paths: PathsHome,
    components: ComponentsHome,
    defaultRoute: RouteHomeDefault,
    displayInMenu: false,
  },
  CCRN: {
    id: 'CCRN',
    routes: RoutesCcrn,
    paths: PathsCcrn,
    components: ComponentsCcrn,
    defaultRoute: RouteCcrnDefault,
  },
  RESEARCH_PORTFOLIO: {
    id: 'RESEARCH_PORTFOLIO',
    routes: RoutesResearchPortfolio,
    paths: PathsResearchPortfolio,
    components: ComponentsResearchPortfolio,
    defaultRoute: RouteResearchPortfolioDefault,
  },
  REPORTS: {
    id: 'REPORTS',
    routes: RoutesReports,
    paths: PathsReports,
    components: ComponentsReports,
    defaultRoute: RouteReportsDefault,
  },
  PUBLICATIONS: {
    id: 'PUBLICATIONS',
    routes: RoutesPublications,
    paths: PathsPublications,
    components: ComponentsPublications,
    defaultRoute: RoutePublicationsDefault,
  },
  CONTACT: {
    id: 'CONTACT',
    routes: RoutesContact,
    paths: PathsContact,
    components: ComponentsContact,
    defaultRoute: RouteContactDefault,
  },
  GENERAL: {
    id: 'GENERAL',
    routes: RoutesGeneral,
    paths: PathsGeneral,
    components: ComponentsGeneral,
    defaultRoute: RouteGeneralDefault,
    displayInMenu: false,
  },
  ACCOUNT: {
    id: 'ACCOUNT',
    routes: RoutesAccount,
    paths: PathsAccount,
    components: ComponentsAccount,
    defaultRoute: RouteAccountDefault,
    displayInMenu: false,
  },
};

export const { HOME: DEFAULT_MODULE } = MODULES;
export const DEFAULT_ROUTE = RouteHomeDefault;
export default MODULES;