import { types } from "mobx-state-tree";

/**
 * ApiResponse model. Item model is left to be defined by the new model "inheriting" from ApiResponse.
 */
const ApiResponseModel = types.model("ApiResponseModel", {
  Success: types.maybeNull(types.boolean),
  ErrorMessage: types.maybeNull(types.string),
  // Item: Must be defined by the new model "inheriting" from ApiResponse.
  // Type should be types.maybeNull(types.model({...}) in case the response does not have an Item. Can happen when Success is false.
});

export default ApiResponseModel;
