export const ROUTES = {
  HOME: {
    id: 'HOME',
    name: 'Home',
    iconI18N: 'main:header.app-logo',
    icon: 'ccrn_logo.png',
    linkClass: 'logo',
    i18nId: 'main:menu-label',
    displayInMenu: false,
  },
};

export const { HOME: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
