import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';

import { SUSPENSE_FADE_DURATION } from '../../../constants';
import ccrnLogo from '../../../assets/ccrn_logo_100.png';

import '../../modules/reports/legos/cards.scss';
import './ccrn-loading.scss';

const CCRNLoading = () => {
  // True: Fade in
  // False: Fade out
  const [showFade, setShowFade] = useState(true);

  // Fade in and out logo every 1.25 second
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFade(showFade => !showFade);
    }, SUSPENSE_FADE_DURATION);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fade in={ true }>
      <div className='ccrn-loading'>
        <Fade in={ showFade } timeout={ SUSPENSE_FADE_DURATION }>
          <img src={ ccrnLogo } className='loading-screen-logo' alt='CCRN Logo'/>
        </Fade>
      </div>
    </Fade>
  );
};

export default CCRNLoading;
