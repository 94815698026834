/* eslint-disable no-console */
/* eslint-disable import/extensions */
import Cookies from 'js-cookie';
import i18next from 'i18next';

import i18n from '../i18n/i18n.js';

import { supportedLanguages } from '../i18n/supported-languages';

import ApplicationStore from '../stores/application-store';

export const preLoadAppResources = () => {
  const {
    setStoreAttribute,
    isBackendAlive,
    setUserLocale,
  } = ApplicationStore;

  const userLocale = Cookies.get('user_locale') || supportedLanguages[0];
  setUserLocale(userLocale);

  setStoreAttribute('i18nLoaded', false);
  isBackendAlive();

  i18n(() => {
    i18next.changeLanguage(userLocale);
    setStoreAttribute('i18nLoaded', true);
  });
};
export default preLoadAppResources;
