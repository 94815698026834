import Cookies from 'js-cookie';
import { types } from 'mobx-state-tree';
import { includes, uniqueId } from 'lodash';
import { v4 as uuid } from 'uuid';

import ApiActionFactory from '../api/api-action-factory';
import { isDev } from '../utils/common';
import StoreFactory from './factories/store';
import { Resolution } from './models/model-enums';
import NotificationModel from './models/notification-model';
import ApplicationViewportModel from './models/application-viewport-model';
import UserStore from './user-store';

import Variables from '../styles/legos/variables.module.scss';

import {
  API_KEYS,
  TESTING_JSON,
} from '../api/endpoints';

const {
  BreakpointXsmall,
  BreakpointSmall,
  BreakpointMedium,
  BreakpointLarge,
  BreakpointXlarge,
} = Variables;

const getResolution = (width) => {
  if (width > parseInt(BreakpointXsmall, 10) && width <= parseInt(BreakpointSmall, 10)) {
    return Resolution.SMALL;
  }
  if (width > parseInt(BreakpointSmall, 10) && width <= parseInt(BreakpointMedium, 10)) {
    return Resolution.MEDIUM;
  }
  if (width > parseInt(BreakpointMedium, 10) && width <= parseInt(BreakpointLarge, 10)) {
    return Resolution.LARGE;
  }
  if (width > parseInt(BreakpointLarge, 10) && width <= parseInt(BreakpointXlarge, 10)) {
    return Resolution.XLARGE;
  }
  if (width > parseInt(BreakpointXlarge, 10)) {
    return Resolution.XXLARGE;
  }
  return Resolution.XSMALL;
};


const Application = StoreFactory({
  modelName: 'ApplicationStore',
  models: [
    ApiActionFactory({
      loadApiKeys: {
        endpoint: API_KEYS,
        target: 'apiKeys',
        onTrigger(self) {
          console.log('TRYING TO LOAD keys')
        },
        onLoad(self) {
          console.log('KEYS loaded: ', self.apiKeys)
        }
      },
      isBackendAlive: {
        endpoint: TESTING_JSON,
        target: 'testing',
      },
    }),
  ],
  modelStructure: {
    isLoading: false,
    userLocale: '',
    i18nLoaded: false,
    apiKeys: types.maybeNull(types.model('API_KEYS', { backendUrl: '', credApiKey: '', googleApiKey: '' })),
    notifications: types.map(NotificationModel),
    isMobile: types.maybeNull(types.boolean),
    resolution: types.maybeNull(types.string),
    applicationViewport: types.maybeNull(ApplicationViewportModel),
  },
})
  .volatile(self => ({
    cookie_user_locale: Cookies.get('user_locale') || '',
  }))
  .actions(self => ({
    setViewportSize(newSize) {
      const newResolution = getResolution(newSize.width);
      const isMobile = includes([Resolution.XSMALL, Resolution.SMALL, Resolution.MEDIUM], newResolution);

      self.applicationViewport = newSize;
      self.isMobile = isMobile;
      self.resolution = newResolution;
    },
    async afterCreate() {
      let cacheBust = Cookies.get('cache_buster');
      if(!cacheBust) {
        cacheBust = uuid();
        Cookies.set('cache_buster', cacheBust, { expires: 0.5 })
      }

      const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
      };

      const handleResize = () => self.setViewportSize(getWindowDimensions());
      window.addEventListener('resize', handleResize);
      handleResize();

      await self.loadApiKeys(cacheBust);

      // Must be after loadApiKeys
      if (UserStore.isLoggedIn()) {
        UserStore.retrieveUserMethodList();
      }
    },
    setUserLocale(newLocale) {
      self.cookie_user_locale = newLocale;
      Cookies.set('user_locale', newLocale);

      document.documentElement.lang = newLocale;
    },
    addSnackbar(snackbar) {
      const id = uniqueId();

      self.notifications.set(id, {
        ...snackbar,
        id,
      });
    },
    removeSnackbar(id) {
      self.notifications.delete(id);
    },
  }))
  .views(self => ({
    getUserLocale() {
      return self.cookie_user_locale || 'en-CA';
    },
    applicationIsLoaded() {
      return (
        self.i18nLoaded
      );
    },
    get googleMapKey() {
      return self.apiKeys?.googleApiKey || 'googleApiKey';
    },
    get credKey() {
      return self.apiKeys?.credApiKey || 'credApiKey';
    },
    get credTarget() {
      return self.apiKeys?.backendUrl || 'backendUrl';
    },
  }));

const ApplicationStore = Application.create();

if (window && isDev()) {
  window.ApplicationStore = ApplicationStore || {};
}

export default ApplicationStore;
