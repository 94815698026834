import { types } from 'mobx-state-tree';
import ApiResponse from '../../models/api-response-model';

const UserMethodListModel = ApiResponse.named('UserMethodListModel').props({
  Item: types.maybeNull(
    types.model({
      authExpiration: types.string,
      controllerMethodList: types.array(
        types.model({
          ControllerName: types.string,
          MethodName: types.string,
          isAllowed: types.boolean,
        })
      ),
    })
  ),
});

export default UserMethodListModel;
