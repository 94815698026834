import { APP_NAME } from '../../constants';

const moduleRoot = `${ APP_NAME }/general`;

export const PATHS = {
  GENERAL: {
    path: `${ moduleRoot }`,
  },
  FEATURE_FLAGS: {
    path: `${ moduleRoot }/feature-flags`,
  },
};

export default PATHS;
