/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { find } from 'lodash';

import StoreFactory from './factories/store';

import CcrnStore from './content-stores/ccrn-store';
import DeciderStore from './content-stores/decider-store';
import MoneyStore from './content-stores/money-store';
import PublicStore from './content-stores/public-store';
import PublicationsStore from './content-stores/publications-store';
import ReportsStore from './content-stores/reports-store';
import ResearchStore from './content-stores/research-store';
import StudiesStore from './content-stores/studies-store';
import MetaDataStore from './content-stores/meta-data-store';

import { isDev } from '../utils/common';

const Content = StoreFactory({
  modelName: 'ContentStore',
  models: [],
  modelStructure: {
    CcrnStore: types.maybeNull(CcrnStore),
    DeciderStore: types.maybeNull(DeciderStore),
    MoneyStore: types.maybeNull(MoneyStore),
    PublicStore: types.maybeNull(PublicStore),
    PublicationsStore: types.maybeNull(PublicationsStore),
    ReportsStore: types.maybeNull(ReportsStore),
    ResearchStore: types.maybeNull(ResearchStore),
    StudiesStore: types.maybeNull(StudiesStore),
    MetaDataStore: types.maybeNull(MetaDataStore),
  },
})
  .views(self => ({
    isLoading() {
      return find(self, (model, key) => model?.isLoading);
    },
    isDataLoading() {
      return find(self, (model, key) => model?.isDataLoading) ? true : false ;
    }
  }));

const ContentStore = Content.create({
  CcrnStore: {},
  DeciderStore: {},
  InterventionsStore: {},
  MoneyStore: {},
  PublicStore: {},
  ReportsStore: {},
  ResearchStore: {},
  StudiesStore: {},
  PublicationsStore: {},
  MetaDataStore: {},
});

if (window && isDev()) {
  window.ContentStore = ContentStore || {};
}

export default ContentStore;
