import { types } from 'mobx-state-tree';
import { uniqBy } from 'lodash';

import StoreFactory from '../factories/store';
import ApiActionFactory from '../../api/api-action-factory';

import { isDev } from '../../utils/common';
import { API_CALL_CACHE_DURATION } from '../../constants';

import MetaDataModelWrapper from './models/meta-data-model';

import {
  META_DATA_ENDPOINT
} from '../../api/endpoints';


const MetaDataStore = StoreFactory({
  modelName: 'MetaDataStore',
  models: [
    ApiActionFactory({
      loadMetaData: {
        endpoint: META_DATA_ENDPOINT,
        target: 'metaData',
        targetObjectId: 'locale',
        onTrigger(self) {
          self.isLoading = true;
        },
        onLoad(self, locale) {
          // Remove interventions duplicates
          self.setInterventions(locale, uniqBy(self.metaData.get(locale).Item.interventions, 'Name'));

          self.setMetaDataExpiration(locale, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentMetaData(locale);
          self.isLoading = false;
        },
        onError(self) {
          self.isLoading = false;
        }
      }
    })
  ],
  modelStructure: {
    metaData: types.map(MetaDataModelWrapper),
    metaDataExpiration: types.map(types.number), // key: locale, value: expiration
    currentMetaData: '',
    isLoading: false,
  },
})
  .actions(self => ({
    setCurrentMetaData(locale) {
      self.currentMetaData = locale;
    },
    setMetaDataExpiration(locale, expiration) {
      self.metaDataExpiration.set(locale, expiration);
    },
    setInterventions(locale, interventions) {
      self.metaData.get(locale).Item.interventions = interventions;
    }
  }))
  .views(self => ({
    retrieveMetaData(locale) {
      const metaDataAlreadyExists = self.metaData.has(locale);
      const metaDataExpired = metaDataAlreadyExists && self.metaDataExpiration.get(locale) < Date.now();

      if (metaDataAlreadyExists && !metaDataExpired) {
        self.setCurrentMetaData(locale);
      }
      else {
        self.loadMetaData({'locale': locale});
      }
    },
    get countriesCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.countries;
    },
    get provincesCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.provinces;
    },
    get interventionsCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.interventions;
    },
    get fundersCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.funders;
    },
    get endorsingNetworksCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.endorsingNetworks;
    },
    get populationTypesCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.populationTypes;
    },
    get populationAgesCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.populationAges;
    },
    get sitePopulationAgesCollection() {
      return self?.metaData?.get(self.currentMetaData)?.Item?.sitePopulationAges;
    },
    get isDataLoading() {
      return self.isLoading;
    }
  }));

if (window && isDev()) {
  window.MetaDataStore = MetaDataStore || {};
}

export default MetaDataStore;
