import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'mobx-react';

import AppTheme from './app-theme';

import CCRN from './app/ccrn';
import reportWebVitals from './reportWebVitals';

import ApplicationStore from './stores/application-store';
import ContentStore from './stores/content-store';
import UserStore from './stores/user-store';
import { preLoadAppResources } from '../src/utils/app_init';

import './index.scss';

preLoadAppResources();

ReactDOM.render(
  <Provider UserStore={UserStore}>
    <Provider ApplicationStore={ApplicationStore} ContentStore={ContentStore}>
      <ThemeProvider theme={AppTheme}>
        <Suspense fallback={<div />}>
          <CCRN />
        </Suspense>
      </ThemeProvider>
    </Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
