export const ROUTES = {
  RESEARCH: {
    id: 'RESEARCH',
    name: 'Research',
    i18nId: 'reports:menu-label',
    displayInMenu: false,
  },
  GENERAL_PUBLIC: {
    id: 'GENERAL_PUBLIC',
    name: 'General Public',
    i18nId: 'target_audience:modules.generalPublic.menu-label',
  },
  RESEARCHERS: {
    id: 'RESEARCHERS',
    name: 'Researchers',
    i18nId: 'target_audience:modules.researchers.menu-label',
  },
  GOVERNMENTS: {
    id: 'GOVERNMENTS',
    name: 'Governments',
    i18nId: 'target_audience:modules.governments.menu-label',
  },
  INVESTORS: {
    id: 'INVESTORS',
    name: 'Investors',
    i18nId: 'target_audience:modules.investors.menu-label',
  },
};

export const { RESEARCH: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
