export const ROUTES = {
  CONTACT: {
    id: 'CONTACT',
    name: 'Contact',
    i18nId: 'contact:menu-label',
    displayInMenu: false,
  },
  CONTACT_LINK: {
    id: 'CONTACT_LINK',
    name: 'Contact link',
    i18nId: 'contact:sub-menu-label',
    displayInMenu: false,
    displayInFooterMenu: true,
  },
  U_DE_S: {
    id: 'U_DE_S',
    name: 'Université de Sherbrooke',
    i18nId: 'contact:u-de-s.name',
    icon: 'logo-udes-black.png',
    linkClass: 'logo-udes',
    displayInMenu: false,
  },
};

export const { CONTACT: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
