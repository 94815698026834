import React, { Suspense, lazy, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { map } from 'lodash';
import { observer } from "mobx-react";

import { MODULES } from '../modules/modules';
import CCRNLoading from '../components/common/ccrn-loading/ccrn-loading';
import { injectStores } from "../stores/factories/store-utils";
import Authorization from '../components/common/authorization/authorization';

import './ccrn.scss';

const Header = lazy(() => import('../components/header/header'));
const Footer = lazy(() => import('../components/footer/footer'));
const Landing = lazy(() => import('../components/common/landing'));
const SnackbarContainer =  lazy(() => import('../components/common/snackbar/snackbar-container'));
const PageNotFound = lazy(() => import('../components/modules/common/page-not-found'));
const SessionTimeout = lazy(() => import('../components/common/session-timeout/session-timeout'));
const SessionExpiredDialog = lazy(() => import('../components/common/session-timeout/session-expired-modal'));

const renderRouteComponent = (module, route, isLoggedIn) => {
  const { routes, paths, components } = module;
  const { id } = route;
  const routeHasPath = paths && id && paths[id];
  const routeHasComponent = routes && id && components[id];

  if (routeHasPath && routeHasComponent) {
    const Component = components[id];
    return (
      <Route key={id} exact path={paths[id].path}>
        {paths[id].requiredMethods ? (
          <Authorization methods={paths[id].requiredMethods}>
            <Suspense fallback={<CCRNLoading />}>
              <Component />
            </Suspense>
          </Authorization>
        ) : (
          <Suspense fallback={<CCRNLoading />}>
            <Component />
          </Suspense>
        )}
      </Route>
    );
  }
  return null;
};

const getModuleSubRoutes = (isLoggedIn) =>
  map(MODULES, (module) =>
    map(module.routes, (route) =>
      renderRouteComponent(module, route, isLoggedIn)
    )
  );

const getRoutes = (isLoggedIn) =>
  map(MODULES, (module) =>
    renderRouteComponent(module, module.defaultRoute, isLoggedIn)
  );

const CCRN = (props) => {
  const {
    UserStore: {
      isLoggedIn,
      logout,
    },
  } = props;

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const handleSessionExpiredModalClose = () => {
    setShowSessionExpiredModal(false);
  };

  return (
    <CookiesProvider>
      <Router>
        <div className='ccrn-app'>
          <Switch>
            <Route exact path='/' component={() => <Landing />} />
            <Route>
              <div className='full-width-contained top-level'>
                <Header />
              </div>
              <div id='main' className='full-width-contained main-content'>
                <Switch>
                  {getRoutes(isLoggedIn())}
                  {getModuleSubRoutes(isLoggedIn())}

                  {/* 404 Not Found */}
                  <Route path='*' component={PageNotFound} />
                </Switch>
                <div className='full-width-contained'>
                  <Footer />
                </div>
              </div>
              <SnackbarContainer />
              {isLoggedIn() && (
                <Suspense fallback={<div />}>
                  <SessionTimeout
                    onLogout={logout}
                    setShowSessionExpiredModal={setShowSessionExpiredModal}
                  />
                </Suspense>
              )}
              {showSessionExpiredModal && (
                <Suspense fallback={<div />}>
                  <SessionExpiredDialog
                    open={showSessionExpiredModal}
                    onClose={handleSessionExpiredModalClose}
                  />
                </Suspense>
              )}
            </Route>
          </Switch>
        </div>
      </Router>
    </CookiesProvider>
  );
};

export default injectStores(['UserStore'])(observer(CCRN));
