import { types } from 'mobx-state-tree';

/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';
import StoreFactory from '../factories/store';
import ApiActionFactory from '../../api/api-action-factory';
import PublicationsModel from './models/publications-model';

import { PUBLICATIONS_JSON } from '../../api/endpoints';
import { API_CALL_CACHE_DURATION } from '../../constants';

const PublicationsStore = StoreFactory({
  modelName: 'PublicationsStore',
  models: [
    ApiActionFactory({
      loadPublications: {
        endpoint: PUBLICATIONS_JSON,
        target: 'publicationsCollection',
        onLoad(self) {
          self.publicationsCollectionExpiration = Date.now() + API_CALL_CACHE_DURATION;
        }
      },
    }),
  ],
  modelStructure: {
    publicationsCollection: types.maybeNull(PublicationsModel),
    publicationsCollectionExpiration: types.maybeNull(types.number),
    isLoading: false,
    title: 'PublicationsStore',
  },
})
  .actions(self => ({
    retrievePublications() {
      if (self.publicationsCollectionExpiration && self.publicationsCollectionExpiration > Date.now()) {
        return;
      }

      self.loadPublications();
    }
  }))
  .views(self => ({
    get publications() {
      return self.publicationsCollection?.Item;
    }
  }));

if (window && isDev()) {
  window.PublicationsStore = PublicationsStore || {};
}

export default PublicationsStore;
