/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import {
  flow,
  types,
} from 'mobx-state-tree';
import {
  mapValues,
} from 'lodash';
import { t } from 'i18next';

import ApplicationStore from '../stores/application-store';
import { fetchData } from './handle-requests';

const ApiActionFactory = storeApi => (
  types.model({})
    .actions(
      (self) => (
        mapValues(
          storeApi,
            (endpointConfig) => (
              /* eslint-disable-next-line max-statements */
              flow(function* genericApi(formParams, payload) {
                try {
                  const options = {};

                  const {
                    endpoint,
                    target,
                    targetObjectId,
                    targetArray,
                    onTrigger,
                    onLoad,
                  } = endpointConfig;

                  if (self.isLoading !== undefined) {
                    self.isLoading = true;
                  }

                  if (target || targetArray) {
                    self?.loadingCalls?.add?.(target || targetArray);
                  }

                  if (onTrigger) {
                    // Special function to call before BE call is made
                    onTrigger(self, formParams?.[targetObjectId]);
                  }

                  const response = yield fetchData({
                    endpoint,
                    formParams,
                    ...options,
                  });

                  // If the call was canceled, don't do anything
                  if (response?.canceled) {
                    return;
                  }

                  // eslint-disable-next-line require-atomic-updates
                  try {
                    if (targetArray) {
                      self[targetArray].push(response.data || response)
                    } else if (targetObjectId) {
                      if (!self[target]) {
                        self[target] = {};
                      }
                      self[target].set(formParams[targetObjectId], (response.data|| response))
                    } else if (target) {
                      self[target] = response.data || response;
                    }

                    if (onLoad) {
                      // Special function to call after BE call returns
                      onLoad(self, formParams?.[targetObjectId]);
                    }
                  } catch (err) {
                    const {
                      onError,
                    } = endpointConfig;

                    console.error('MobX State Tree Error: Response data could not be set: ', err);

                    if (onError) {
                      // Special function to call if BE call returns errors
                      onError(self, new Error(t('common:snackbar.error-message')));
                    }

                    const snackbar = {
                      message: 'MobX State Tree Error: Response data could not be set.',
                      endpoint: endpointConfig?.endpoint?.url(formParams),
                      i18nId: 'error-message',
                      type: 'api-error',
                    };

                    ApplicationStore.addSnackbar(snackbar);
                  }

                  if (self.isLoading !== undefined) {
                    self.isLoading = false;
                  }

                  if (target || targetArray) {
                    self?.loadingCalls?.delete?.(target || targetArray);
                  }
                } catch (err) {
                  const {
                    onError,
                    targetArray,
                    target,
                  } = endpointConfig;

                  if (self.isLoading !== undefined) {
                    self.isLoading = false;
                  }

                  if (target || targetArray) {
                    self?.loadingCalls?.delete?.(target || targetArray);
                  }

                  if (onError) {
                    // Special function to call if BE call returns errors
                    onError(self, err);
                  }
                  console.error('Woopelay: ', err);
                  console.log(' #=================================================#');
                  console.log('||                                                 ||');
                  console.log('||  Should probably put a popup or a toaster here  ||');
                  console.log('||                                                 ||');
                  console.log(' #=================================================#');

                  const snackbar = {
                    message: err?.response?.data?.message || err?.message || 'Something went wrong',
                    endpoint: endpointConfig?.endpoint?.url(formParams),
                    i18nId: 'error-message',
                    type: 'api-error',
                  };
                  ApplicationStore.addSnackbar(snackbar);
                }
              })
            )
        )
      )
    ));

export default ApiActionFactory;
