/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';

import StoreFactory from '../factories/store';

const DeciderStore = StoreFactory({
  modelName: 'DeciderStore',
  models: [],
  modelStructure: {
    isLoading: false,
    title: 'DeciderStore',
  },
});

if (window && isDev()) {
  window.DeciderStore = DeciderStore || {};
}

export default DeciderStore;
