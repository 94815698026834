import { types } from 'mobx-state-tree';
import ApiResponse from '../../models/api-response-model';

export const SingleUserInfoModel = types.model({
  userId: types.integer,
  userRoleId: types.integer,
  userRoleName: types.string,
  email: types.string,
  userName: types.string,
  userCompleteName: types.string,
  actif: types.boolean,
});

const UserInfoModel = ApiResponse.named('UserInfoModel').props({
  Item: types.maybeNull(
    types.model({
      authExpiration: types.maybeNull(types.string),
      userInfo: SingleUserInfoModel,
    })
  ),
});

export default UserInfoModel;
