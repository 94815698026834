/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';

import StoreFactory from '../factories/store';

const ResearchStore = StoreFactory({
  modelName: 'ResearchStore',
  models: [],
  modelStructure: {
    isLoading: false,
    title: 'ResearchStore',
  },
});

if (window && isDev()) {
  window.ResearchStore = ResearchStore || {};
}

export default ResearchStore;
