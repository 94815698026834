import { types } from 'mobx-state-tree';

// import { map } from 'lodash';

export const LinkModel = types.model(
  'LinkModel',
  {
    Url: '',
    Title: '',
  }
);

export const StudiesModel = types.model(
  'StudiesModel',
  {
    Id: 0,
    Name: "",
    ShortName: "",
    Description: "",
    Funding: "",
    Intervention: "",
    IsBiobank: types.boolean,
    IsCompleted: types.boolean,
    IsNotStarted: types.boolean,
    IsOngoing: types.boolean,
    IsInterventional: types.boolean,
    IsObservational: types.boolean,
    IsStandaloneBiobank: types.boolean,
    Keywords: "",
    PatientPartner: types.maybeNull(types.string),
    PatientPartnerNbIndividuals: types.maybeNull(types.integer),
    PatientPartnerRoles: types.maybeNull(types.string),
    PatientPartnerRolesOther: types.maybeNull(types.string),
    LogoUrl: "",
    LogoThumbnailUrl: "",
    PrincipalInvestigatorName: "",
    SecondaryInvestigatorName: "",
    ThirdInvestigatorName: "",
    Status: "",
    TargetPopulationAge: types.array(types.string),
    TargetPopulationOther: "",
    TargetPopulations: types.maybeNull(types.array(types.string)),
    Type: "",
    WebsiteLinks: types.maybeNull(types.array(LinkModel)),
    WebsiteUrls: types.maybeNull(types.array(types.string)),
    DataIsPartial: types.maybeNull(types.boolean),
  }
);

const StudiesModelWrapper = types.model(
  'StudiesModel',
  {
    Item: types.array(types.maybeNull(StudiesModel)),
  }
);

export default StudiesModelWrapper;
