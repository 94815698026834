import { Suspense, lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { injectStores } from '../../../stores/factories/store-utils';

import './authorization.scss';

const UnauthorizedPage = lazy(() =>
  import('../../modules/authorization/unauthorized')
);

// Authorize a page based on user's permissions
const Authorization = (props) => {
  const { t } = useTranslation();
  const {
    UserStore: { isLoggedIn, userMethodList },
    children,
    methods, // User must have permission for all methods
  } = props;

  if (!isLoggedIn()) {
    return <Redirect to='/account/login' />;
  }

  // Return null if userMethodList hasn't loaded yet
  if (!userMethodList) {
    return children;
  }

  // Check if user has permission for all methods
  if (!methods || methods.some((method) => !userMethodList?.includes(method))) {
    console.log(`User does not have permission for ${methods} method(s)`);

    return (
      <Suspense
        fallback={
          <div>
            <h1 className='authorization-title'>
              {t('user:authorization.unauthorized-access.title')}
            </h1>
          </div>
        }
      >
        <UnauthorizedPage />
      </Suspense>
    );
  }

  return children;
};

export default injectStores(['UserStore'])(observer(Authorization));
