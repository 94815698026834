import { types } from 'mobx-state-tree';
import { map } from 'lodash';

const DataPoint = types.model(
  'DataPoint',
  {
      dateKey: 0,
      Year: 0,
      Month: 0,
      NewPatients: 0,
      TotalPatients: 0,
  }
);

const RecruitmentHistory = types.model(
  'RecruitmentHistory',
  {
      color: '',
      dataPoints: types.array(DataPoint),
  }
);

const RecruitmentDataModel = types.snapshotProcessor(types.model(
  'RecruitmentDataModel',
  {
      data: types.array(RecruitmentHistory),
  }
), {
    preProcessor(sn) {
        return {
            data: map(sn.Item, (rh, rhId) => (
              {
                  color: rhId,
                  dataPoints: [
                      ...map(rh, i => {
                          return {
                              ...i,
                              dateKey: (i.Year * 100) + i.Month,
                          }
                      }),
                  ],
              }
            ))
        }
    }
});

export default RecruitmentDataModel;
