import { types } from 'mobx-state-tree';

const ApplicationViewportModel = types.model(
  'StudyModel',
  {
    width: types.number,
    height: types.number,
  }
);

export default ApplicationViewportModel;
