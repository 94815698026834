import { types } from 'mobx-state-tree';

const ReportCardAlertModel = types.model(
  'ReportCardAlert',
  {
    id: types.string,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
  }
);

export default ReportCardAlertModel;
