export const ROUTES = {
  CCRN: {
    id: 'CCRN',
    name: 'CCRN',
    i18nId: 'ccrn:menu-label',
    displayInMenu: false,
  },
  VISION: {
    id: 'VISION',
    name: 'Vision',
    i18nId: 'ccrn:modules.vision.menu-label',
  },
  MISSION: {
    id: 'MISSION',
    name: 'Mission',
    i18nId: 'ccrn:modules.mission.menu-label',
  },
  CORE_VALUES: {
    id: 'CORE_VALUES',
    name: 'Core values',
    i18nId: 'ccrn:modules.core-values.menu-label',
  },
  FOCUS_AREAS: {
    id: 'FOCUS_AREAS',
    name: 'Focus areas',
    i18nId: 'ccrn:modules.focus-areas.menu-label',
  },
  TEAM: {
    id: 'TEAM',
    name: 'Team',
    i18nId: 'ccrn:modules.team.menu-label',
  },
};

export const { CCRN: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
