import { lazy } from 'react';

const Research = lazy(() => import('../../components/modules/reports/reports'));

export const COMPONENTS = {
  RESEARCH: () => <Research dominant='NONE' />,
  GENERAL_PUBLIC: () => <Research dominant='GENERAL_PUBLIC' />,
  RESEARCHERS: () => <Research dominant='RESEARCHERS' />,
  GOVERNMENTS: () => <Research dominant='GOVERNMENTS' />,
  INVESTORS: () => <Research dominant='INVESTORS' />,
};

export default COMPONENTS;
