import { APP_NAME } from '../../constants';

const moduleRoot = `${APP_NAME}/account`;

export const PATHS = {
  ACCOUNT: {
    path: `${moduleRoot}`,
    requiredMethods: ['GetUserInfo'],
  },
  LOGIN: {
    path: `${moduleRoot}/login`,
  },
  FORGOT_PASSWORD: {
    path: `${moduleRoot}/forgot-password`,
  },
  CREATE_ACCOUNT: {
    path: `${moduleRoot}/create-account`,
    requiredMethods: ['RegisterUser', 'GetRoleList'],
  },
  RESET_PASSWORD: {
    path: `${moduleRoot}/reset-password`,
  },
  SITE_PERMISSIONS: {
    path: `${moduleRoot}/site-permissions`,
    requiredMethods: [
      'CreateUserSiteSecurite',
      'GetAllUserInfoList',
      'GetSiteList',
      'GetUserSiteList',
    ],
  },
};

export default PATHS;
