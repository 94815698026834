import { types } from "mobx-state-tree";
import ApiResponse from "../../models/api-response-model";

const LoginInfoModel = ApiResponse.named('LoginInfoModel').props({
  Item: types.maybeNull(
    types.model({
      userId: types.maybeNull(types.integer),
      userRoleId: types.maybeNull(types.integer),
      authKey: types.maybeNull(types.string),
      authExpiration: types.maybeNull(types.string),
      twoFactorAuthRequired: types.optional(types.boolean, false),
      email: types.maybeNull(types.string),
    })
  ),
});

export default LoginInfoModel;
