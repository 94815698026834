export const ROUTES = {
  ACCOUNT: {
    id: 'ACCOUNT',
    name: 'ACCOUNT',
    i18nId: 'account:menu-label',
    displayInMenu: false,
  },
  LOGIN: {
    id: 'LOGIN',
    name: 'LOGIN',
    i18nId: 'login:menu-label',
    displayInMenu: false,
  },
  FORGOT_PASSWORD: {
    id: 'FORGOT_PASSWORD',
    name: 'FORGOT_PASSWORD',
    i18nId: 'forgot-password:menu-label',
    displayInMenu: false,
  },
  CREATE_ACCOUNT: {
    id: 'CREATE_ACCOUNT',
    name: 'CREATE_ACCOUNT',
    i18nId: 'create-account:menu-label',
    displayInMenu: false,
  },
  RESET_PASSWORD: {
    id: 'RESET_PASSWORD',
    name: 'RESET_PASSWORD',
    i18nId: 'reset-password:menu-label',
    displayInMenu: false,
  },
  SITE_PERMISSIONS: {
    id: 'SITE_PERMISSIONS',
    name: 'SITE_PERMISSIONS',
    i18nId: 'site-permissions:menu-label',
    displayInMenu: false,
  },
};

export const { ACCOUNT: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
