import { APP_NAME } from '../../constants';

const moduleRoot = `${ APP_NAME }/reports`;

export const PATHS = {
  RESEARCH: { path: `${ moduleRoot }` },
  GENERAL_PUBLIC: { path: `${ moduleRoot }/general-public` },
  RESEARCHERS: { path: `${ moduleRoot }/researchers` },
  GOVERNMENTS: { path: `${ moduleRoot }/governments` },
  INVESTORS: { path: `${ moduleRoot }/investors` },
};

export default PATHS;
