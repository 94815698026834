import { lazy } from 'react';

const FeatureFlags = lazy(() => import('../../utils/feature-flags-toggle'));
const General = () => <div>GENERAL</div>
export const COMPONENTS = {
  GENERAL: General,
  FEATURE_FLAGS: FeatureFlags,
};

export default COMPONENTS;
