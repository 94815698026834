import { types } from 'mobx-state-tree';
import { map } from 'lodash';
import ApiResponse from '../../models/api-response-model';

const SiteListModel = types.snapshotProcessor(
  ApiResponse.named('SiteListModel').props({
    Item: types.maybeNull(
      types.model({
        authExpiration: types.maybeNull(types.string),
        siteList: types.array(
          types.model({
            siteId: types.string,
            siteName: types.string,
          })
        ),
      })
    ),
  }),
  {
    preProcessor(sn) {
      return {
        Item: {
          authExpiration: sn.authExpiration,
          siteList: map(sn?.Item?.siteList, (site, siteId) => ({
            siteId,
            siteName: site,
          })),
        },
      };
    },
  }
);

export default SiteListModel;
