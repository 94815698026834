/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { map, sortBy } from 'lodash';

import StoreFactory from '../factories/store';
import ApiActionFactory from '../../api/api-action-factory';

import { hashParams, isDev } from '../../utils/common';
import { API_CALL_CACHE_DURATION, EMPTY_DATA_HASH } from '../../constants';

import StudiesModelWrapper from './models/studies-model';
import StudiesSamplesModelWrapper from './models/studies-samples-model';

import {
  STUDIES_ENDPOINT,
  STUDIES_SAMPLES_ENDPOINT,
  STUDIES_VARIABLES_ENDPOINT,
} from '../../api/endpoints';

const StudiesStore = StoreFactory({
  modelName: 'StudiesStore',
  models: [
    ApiActionFactory({
      loadStudies: {
        endpoint: STUDIES_ENDPOINT,
        target: 'studiesMap',
        targetObjectId: 'searchHash',
        onTrigger(self) {
          console.log('[STUDIES STORE] - loadStudies (trigger)')
          self.isStudiesMapLoading = true;
        },
        onError(self) {
          self.isStudiesMapLoading = false;
        },
        onLoad(self, searchHash) {
          self.studiesSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.currentStudiesHash = searchHash;
          self.studiesId = map(self.studiesMap.get(searchHash).Item, study => study.Id);
          self.isStudiesMapLoading = false;
        },
      },
      loadStudiesList: {
        endpoint: STUDIES_ENDPOINT,
        target: 'studiesMap',
        targetObjectId: 'searchHash',
      },
      loadStudiesSamples: {
        endpoint: STUDIES_SAMPLES_ENDPOINT,
        target: 'studiesSamplesMap',
        targetObjectId: 'searchHash',
        onTrigger(self, searchHash) {
          console.log('[STUDIES SAMPLES STORE] - loadStudiesSamples (trigger) :  ', searchHash);
          self.isStudiesMapLoading = true;
        },
        onError(self) {
          self.isStudiesMapLoading = false;
        },
        onLoad(self, searchHash) {
          // Sort the data by study name
          self.studiesSamplesMap.set(searchHash, {
            ...self.studiesSamplesMap.get(searchHash),
            Item: {
              ...self.studiesSamplesMap.get(searchHash).Item,
              data: sortBy(self.studiesSamplesMap.get(searchHash).Item.data, (study) => study.studyShortName)
            }
          });

          self.studiesSamplesSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.currentStudiesSamplesHash = searchHash;
          self.isStudiesMapLoading = false;
        },
      },
      loadStudiesVariables: {
        endpoint: STUDIES_VARIABLES_ENDPOINT,
        target: 'studiesVariablesMap',
        targetObjectId: 'searchHash',
        onTrigger(self, searchHash) {
          console.log('[STUDIES VARIABLES STORE] - loadStudiesVariables (trigger) :  ', searchHash);
          self.isStudiesMapLoading = true;
        },
        onError(self) {
          self.isStudiesMapLoading = false;
        },
        onLoad(self, searchHash) {
          // Sort the data by study name
          self.studiesVariablesMap.set(searchHash, {
            ...self.studiesVariablesMap.get(searchHash),
            Item: {
              ...self.studiesVariablesMap.get(searchHash).Item,
              data: sortBy(self.studiesVariablesMap.get(searchHash).Item.data, (study) => study.studyShortName)
            }
          });

          self.studiesVariablesSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.currentStudiesVariablesHash = searchHash;
          self.isStudiesMapLoading = false;
        }
      }
    }),
  ],
  modelStructure: {
    currentStudiesHash: 0,
    currentStudiesSamplesHash: 0,
    currentStudiesVariablesHash: 0,
    studiesSearchHashes: types.map(types.number),               // key: searchHash, value: expiration
    studiesSamplesMap: types.map(StudiesSamplesModelWrapper),
    studiesSamplesSearchHashes: types.map(types.number),        // key: searchHash, value: expiration
    studiesVariablesMap: types.map(StudiesSamplesModelWrapper),
    studiesVariablesSearchHashes: types.map(types.number),      // key: searchHash, value: expiration
    studiesMap: types.map(StudiesModelWrapper),
    studiesId: types.frozen([]),
    isLoading: false,
    isStudiesMapLoading: false,
    title: 'StudiesStore',
    selectedStudies: types.frozen(new Set()),
  },
})
  .actions(self => ({
    afterCreate() {
      self.studiesMap.set(EMPTY_DATA_HASH, {
        Item: [],
      });

      self.studiesSamplesMap.set(EMPTY_DATA_HASH, {
        Item: {
          categories: [],
          data: [],
        }
      });

      self.studiesVariablesMap.set(EMPTY_DATA_HASH, {
        Item: {
          categories: [],
          data: [],
        }
      });
    },
    retrieveStudies(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.studiesSearchHashes.has(searchHash);
      const hashIsExpired = hashAlreadyExists && self.studiesSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.currentStudiesHash = searchHash;
        self.studiesId = map(self.studiesMap.get(searchHash).Item, study => study.Id);
        return;
      }

      self.loadStudies({...reportRequest, searchHash: searchHash});
    },
    retrieveStudiesList(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.studiesSearchHashes.has(searchHash);
      const hashIsExpired = hashAlreadyExists && self.studiesSearchHashes.get(searchHash) < Date.now();

      if (hashIsExpired) {
        self.studiesSearchHashes.delete(searchHash);
      }
      else if (hashAlreadyExists || searchHash === 0) {
        return;
      }

      self.studiesSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
      self.loadStudiesList({...reportRequest, searchHash: searchHash});
    },
    retrieveStudiesSamples(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.studiesSamplesSearchHashes.has(searchHash);
      const hashIsExpired = hashAlreadyExists && self.studiesSamplesSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.currentStudiesSamplesHash = searchHash;
        return;
      }
      
      self.loadStudiesSamples({...reportRequest, searchHash: searchHash});
    },
    retrieveStudiesVariables(reportRequest) {
      const searchHash = hashParams(reportRequest);      
      const hashAlreadyExists = self.studiesVariablesSearchHashes.has(searchHash);
      const hashIsExpired = hashAlreadyExists && self.studiesVariablesSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.currentStudiesVariablesHash = searchHash;
        return;
      }

      self.loadStudiesVariables({...reportRequest, searchHash: searchHash});
    },
    clearStudiesId() {
      self.studiesId = [];
    },
    resetAllCurrentStudiesHashes() {
      self.currentStudiesHash = 0;
      self.currentStudiesSamplesHash = 0;
      self.currentStudiesVariablesHash = 0;
    },
    setCurrentStudiesHash(hash) {
      self.currentStudiesHash = hash;
    },
    setCurrentStudiesSamplesHash(hash) {
      self.currentStudiesSamplesHash = hash;
    },
    setCurrentStudiesVariablesHash(hash) {
      self.currentStudiesVariablesHash = hash;
    },
    setSelectedStudies(studies) {
      self.selectedStudies = studies;
    },
    clearSelectedStudies() {
      self.selectedStudies = new Set();
    },
  }))
  .views(self => ({
    get studies() {
      return self.studiesMap;
    },
    getStudy(searchHash) {
      return self.studiesMap.get(searchHash)
    },
    getStudies(searchHash) {
      return self.studiesMap.get(searchHash)?.Item.map(s => s.Id) || [];
    },
    getStudySamplesHeaders(searchHash) {
      return self.studiesSamplesMap.get(searchHash)?.Item.categories;
    },
    getStudySamplesData(searchHash) {
      return self.studiesSamplesMap.get(searchHash)?.Item.data;
    },
    getStudyVariablesHeaders(searchHash) {
      return self.studiesVariablesMap.get(searchHash)?.Item.categories;
    },
    getStudyVariablesData(searchHash) {
      return self.studiesVariablesMap.get(searchHash)?.Item.data;
    },
    get isDataLoading() {
      return self.isStudiesMapLoading;
    },
    getPartialStudies(searchHash) {
      return self.studiesMap.get(searchHash)?.Item.filter(s => s.DataIsPartial === true);
    },
    getPartialStudiesIds(searchHash) {
      return self.getPartialStudies(searchHash).map(s => s.Id);
    },
    getIsSomeSelectedStudiesPartial(searchHash) {
      return Array.from(self.selectedStudies).some(studyId => self.getPartialStudiesIds(searchHash).includes(studyId));
    }
  }));

if (window && isDev()) {
  window.StudiesStore = StudiesStore || {};
}

export default StudiesStore;
