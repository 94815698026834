/* eslint-disable import/extensions */
import HttpBackend from 'i18next-http-backend';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { locales } from './locales/index';

import { supportedLanguages } from './supported-languages.js';

const defaultLocale = supportedLanguages[0];

console.log('locales:  ', locales);
console.log('   keys:  ', Object.keys(locales['en-CA']));

/*
    "i18next": "^20.3.5",
    "i18next-http-backend": "^1.4.5",
*/

const i18nConfig = (callThisWhenDone) => {
  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      lng: defaultLocale,
      load: 'currentOnly',
      supportedLngs: supportedLanguages,
      fallbackLng: [defaultLocale],
      debug: true,
      preload: [defaultLocale],
      interpolation: {
        escapeValue: false,
      },
      resources: locales,
      ns: Object.keys(locales['en-CA']),
      defaultNS: 'common',
      react: {
        useSuspense: true,
        wait: true,
      },
    }, () => {
      callThisWhenDone();
    });
};

// const i18nConfig_OLD = (callThisWhenDone) => {
//   i18n
//     .use(HttpBackend)
//     .use(initReactI18next)
//     .init({
//       lng: defaultLocale,
//       load: 'currentOnly',
//       supportedLngs: supportedLanguages,
//       fallbackLng: [defaultLocale],
//       debug: true,
//       preload: [defaultLocale],
//       backend: {
//         loadPath: `${ publicPath }i18n/{{lng}}/{{ns}}.json`,
//       },
//       interpolation: {
//         escapeValue: false,
//       },
//       ns: [
//         'common',
//         'ccrn',
//         'contact',
//         'main',
//         'public',
//         'publications',
//         'reports',
//         'research-portfolio',
//         'general',
//       ],
//       defaultNS: 'common',
//       react: {
//         useSuspense: true,
//         wait: true,
//       },
//     }, () => {
//       callThisWhenDone();
//     });
// };

export default i18nConfig