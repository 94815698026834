import { lazy } from 'react';

const CCRN = lazy(() => import('../../components/modules/ccrn/ccrn'));

export const COMPONENTS = {
  CCRN: () => <CCRN dominant='CCRN' />,
  MISSION: () => <CCRN dominant='MISSION' />,
  VISION: () => <CCRN dominant='VISION' />,
  CORE_VALUES: () => <CCRN dominant='CORE_VALUES' />,
  FOCUS_AREAS: () => <CCRN dominant='FOCUS_AREAS' />,
  TEAM: () => <CCRN dominant='TEAM' />,
};

export default COMPONENTS;
