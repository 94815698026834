export const BACKEND_SERVER = '/';

export const TESTING_JSON = {
  api: 'static-resources/',
  url: () => 'test.json',
};

export const API_KEYS = {
  api: 'static-resources/',
  url: (cacheBust) => `api-keys.json?cachebust=${cacheBust}`,
};

export const CLINICS_JSON = {
  api: 'static-resources/',
  url: () => 'clinics.json',
};

export const TOPO_JSON = {
  api: 'static-resources/',
  url: (data) => {
    return `topo-${data.what}.json`
  },
};

export const PUBLICATIONS_JSON = {
  api: 'static-resources/',
  url: () => `publications.json`,
};

export const STUDIES_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/getStudyList`,
  formData: (data) => ({

    studyFilter: {
      ...data,
    },
  }),
};

export const STUDIES_SAMPLES_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/getSamplesReportData`,
  formData: (data) => ({
    ...data,
  }),
};

export const STUDIES_VARIABLES_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/getVariablesReportData`,
  formData: (data) => ({
    ...data,
  }),
};

export const INTERVENTIONS_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetInterventionList`,
};

export const SITE_MAPS_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetSiteMap`,
  formData: (data) => ({
    ...data,
  }),
};

export const SITE_MAPS_ENDPOINT_V2 = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetSiteMapV2`,
  formData: (data) => ({
    ...data,
  }),
};

export const ENROLLMENT_WINDOW_DATE_DATA_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetEnrollmentWindowDates`,
  formData: (data) => ({
    ...data,
  }),
};

export const RECRUITMENT_DATA_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetSiteRecruitmentData`,
  formData: (data) => ({
    ...data,
  }),
};

export const COUNTRIES_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetCountryList`,
};

export const PATIENTS_PER_SITE_DATA_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetTotalPatientsPerSite`,
  formData: (data) => ({
    ...data,
  }),
};

export const META_DATA_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetMetaData`,
};

export const PUBLICATION_LIST_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetPublicationList`,
  formData: (data) => ({
    ...data,
  }),
};

export const COORDINATING_CENTERS_ENDPOINT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetCoordinatingCenters`,
  formData: (data) => ({
    ...data,
  }),
};

export const PERFORM_USER_LOGIN = {
  method: "POST",
  api: "hard",
  url: () => `/api/crn/PerformUserLogin`,
  formData: (data) => ({
    ...data,
  }),
};

export const PERFORM_USER_LOGOUT = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/PerformUserLogout`,
};

export const VERIFY_MFA = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/VerifyMFA`,
  formData: (data) => ({
    ...data,
  }),
};

export const USER_HANDSHAKE = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/UserHandshake`,
  formData: (data) => ({
    ...data,
  }),
};

export const UPDATE_PASSWORD = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/UpdatePassword`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_USER_INFO = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetUserInfo`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_USER_METHOD_LIST = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetUserMethodList`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_ROLE_LIST = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetRoleList`,
  formData: (data) => ({
    ...data,
  }),
};

export const REGISTER_USER = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/RegisterUser`,
  formData: (data) => ({
    ...data,
  }),
};

export const RESET_PASSWORD_WITH_TOKEN = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/ResetPasswordWithToken`,
  formData: (data) => ({
    ...data,
  }),
};

export const REQUEST_PASSWORD_TOKEN = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/RequestPasswordToken`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_ALL_USER_INFO_LIST = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetAllUserInfoList`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_SITE_LIST = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetSiteList`,
  formData: (data) => ({
    ...data,
  }),
};

export const GET_USER_SITE_LIST = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/GetUserSiteList`,
  formData: (data) => ({
    ...data,
  }),
};

export const CREATE_USER_SITE_SECURITE = {
  method: 'POST',
  api: 'hard',
  url: () => `/api/crn/CreateUserSiteSecurite`,
  formData: (data) => ({
    ...data,
  }),
};
