import { types } from 'mobx-state-tree';

const pub = types.model(
  'pub',
  {
    pis: '',
    title: '',
    publicationLocation: '',
    pubThumbnailUrl: '',
    links: types.array(types.model(
      'links',
      {
        titleI18nId: '',
        url: '',
      }
    )),
  }
);

const PublicationsModel = types.model(
  'PublicationsModel',
  {
      publications: types.maybeNull(types.array(pub)),
  }
);

export default PublicationsModel;
