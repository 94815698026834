/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';

import StoreFactory from '../factories/store';

const MoneyStore = StoreFactory({
  modelName: 'MoneyStore',
  models: [],
  modelStructure: {
    isLoading: false,
    title: 'MoneyStore',
  },
});

if (window && isDev()) {
  window.MoneyStore = MoneyStore || {};
}

export default MoneyStore;
