import { types } from 'mobx-state-tree';
import ApiResponse from '../../models/api-response-model';
import { SingleUserInfoModel } from './user-info-model';

const AllUserInfoModel = ApiResponse.named('AllUserInfoModel').props({
  Item: types.maybeNull(
    types.model({
      authExpiration: types.maybeNull(types.string),
      userInfoList: types.array(SingleUserInfoModel),
    })
  ),
});

export default AllUserInfoModel;
