import { types } from 'mobx-state-tree';

const NotificationModel = types.model(
  'NotificationModel',
  {
    id: '',
    message: '',
    endpoint: '',
    i18nId: '',
    type: '',
    duration: types.maybe(types.union(types.null, types.number)),
  }
);

export default NotificationModel;
