import { types } from 'mobx-state-tree';

import CountryModel from './countries-model';
import InterventionModel from './intervention-model';
import TextValueModel from './text-value-model';

const MetaDataModel = types.model(
    'MetaDataModel',
    {
        countries: types.maybeNull(types.array(CountryModel)),
        provinces: types.maybeNull(types.array(TextValueModel)),
        interventions: types.maybeNull(types.array(InterventionModel)),
        funders: types.maybeNull(types.array(TextValueModel)),
        endorsingNetworks: types.maybeNull(types.array(TextValueModel)),
        populationTypes: types.maybeNull(types.array(TextValueModel)),
        populationAges: types.maybeNull(types.array(TextValueModel)),
        sitePopulationAges: types.maybeNull(types.array(TextValueModel))
    }
);

const MetaDataModelWrapper = types.model(
    'MetaDataModelWrapper',
    {
        Item: types.maybeNull(MetaDataModel)
    }
);

export default MetaDataModelWrapper;
