import {
  every,
  isArray,
  isNull,
  isPlainObject,
  isUndefined,
} from 'lodash';
import i18next from 'i18next';

export const isDev = () => process.env.NODE_ENV === 'development';

export const isHardHttpPath = (path) => {
  return path.substr(0,4) === 'http';
}

export const isRelativePath = (path) => {
  return path.substr(0,1) === '/';
}

export const isNavigationLink = (path) => {
  return isHardHttpPath(path) || isRelativePath(path);
}

export const isEmptyObject = value => {
  if (isPlainObject(value)) {
    return every(value, val => (
      val === undefined
      || val === null
      || val === ''
      || val === 0
      || (isArray(val) && val.length === 0)
    ));
  }
  if (isNull(value)) {
    return true;
  }
  if (isArray(value)) {
    return value.length === 0;
  }
  return isUndefined(value);
};

export const upperSnakeCase = (input = '') => (input.replace(/-/g, '_').toUpperCase());
export const noSpaceDash = (str) => (str.replace(new RegExp(/[\s-]/g), ''));

// eslint-disable-next-line max-statements
export const accentsTidy = (str) => {
  let rep = str;
  rep = rep.replace(new RegExp(/[àáâãäå]/g), 'a');
  rep = rep.replace(new RegExp(/[ÀÁÂÃÄÅ]/g), 'A');
  rep = rep.replace(new RegExp(/[èéêë]/g), 'e');
  rep = rep.replace(new RegExp(/[ÈÉÊË]/g), 'E');
  rep = rep.replace(new RegExp(/[ìíîï]/g), 'i');
  rep = rep.replace(new RegExp(/[ÌÍÎÏ]/g), 'I');
  rep = rep.replace(new RegExp(/[òóôõö]/g), 'o');
  rep = rep.replace(new RegExp(/[ÒÓÔÕÖ]/g), 'O');
  rep = rep.replace(new RegExp(/[ùúûü]/g), 'u');
  rep = rep.replace(new RegExp(/[ÙÚÛÜ]/g), 'U');
  rep = rep.replace(new RegExp(/[ýÿ]/g), 'y');
  rep = rep.replace(new RegExp(/[ÝŸ]/g), 'Y');
  rep = rep.replace(new RegExp(/æ/g), 'ae');
  rep = rep.replace(new RegExp(/Æ/g), 'AE');
  rep = rep.replace(new RegExp(/ç/g), 'c');
  rep = rep.replace(new RegExp(/Ç/g), 'C');
  rep = rep.replace(new RegExp(/ñ/g), 'n');
  rep = rep.replace(new RegExp(/Ñ/g), 'N');
  rep = rep.replace(new RegExp(/œ/g), 'oe');
  rep = rep.replace(new RegExp(/Œ/g), 'OE');
  return rep;
};

export const locationToModuleId = (appPath) => {
  const [currentModuleId, currentSubModuleId] = appPath.substr(1).split('/');
  return {
    currentModuleId: upperSnakeCase(currentModuleId),
    currentSubModuleId: upperSnakeCase(currentSubModuleId),
  };
};

export const eqSet = (xs, ys) =>  xs.size === ys.size &&  [...xs].every((x) => ys.has(x));

export const checkOrNot = (boo) => {
  if(boo === null) {return i18next.t('common:general.na');}
  return boo ? i18next.t('common:general.yes') : i18next.t('common:general.no')
};

export const emptyOrNot = (boo) => {
  if (boo === null || boo === '') {
    return i18next.t('common:general.na');
  }
  if (boo === 'Yes') {
    return i18next.t('common:general.yes');
  }
  if (boo === 'No') {
    return i18next.t('common:general.no');
  }
  return boo;
};

// export const emptyOrNot = (boo) => {
//   if(boo === null || boo === '') { return 'N/A'}
//   return boo ? boo : 'No'
// };

export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for(let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const recursivelyOrderKeys = function(unordered) {
  // If it's an array - recursively order any
  // dictionary items within the array
  if (Array.isArray(unordered)) {
    return unordered.map(item => recursivelyOrderKeys(item));
  }

  // If it's an object - let's order the keys
  if (typeof unordered === 'object') {
    var ordered = {};
    Object.keys(unordered).sort().forEach(function(key) {
      ordered[key] = recursivelyOrderKeys(unordered[key]);
    });
    return ordered;
  }

  return unordered;
};

export function orderedObject(data) {
  var sortedData = recursivelyOrderKeys(data);
  return JSON.stringify(sortedData, null, '  ');
};

export function hashParams(params) {
  return cyrb53(orderedObject({...params, locale: i18next.language}));
}

export function formatPageTitle(title) {
  return `${title} | ${i18next.t('main:header.app-name')}`;
}
