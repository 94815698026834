import { types } from 'mobx-state-tree';
import ApiResponse from '../../models/api-response-model';

const RegisterUserModel = ApiResponse.named('RegisterUserModel').props({
  Item: types.maybeNull(
    types.model({
      userId: types.integer,
      token: types.string,
      tokenExpiration: types.string,
    })
  ),
});

export default RegisterUserModel;
