import { types } from 'mobx-state-tree';
import ApiResponse from '../../models/api-response-model';

const RoleListModel = ApiResponse.named('RoleListModel').props({
  Item: types.maybeNull(
    types.model({
      authExpiration: types.string,
      roleInfoList: types.array(
        types.model({
          Id: types.integer,
          Name: types.string,
        })
      ),
    })
  ),
});

export default RoleListModel;
