import { types } from 'mobx-state-tree';

export const LinkModel = types.model(
  'LinkModel',
  {
    Url: '',
    Title: '',
  }
);

export const SamplesModel = types.model(
  'SamplesModel',
  {
    categories: types.frozen(),
    data: types.frozen(),
  }
);

const StudiesSamplesModelWrapper = types.model(
  'StudiesSamplesModel',
  {
    Item: SamplesModel,
  }
);

export default StudiesSamplesModelWrapper;
