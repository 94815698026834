/* eslint-disable no-param-reassign */
import { applySnapshot, types } from 'mobx-state-tree';
import { map, reduce, sample } from 'lodash';

import StoreFactory from '../factories/store';
import ApiActionFactory from '../../api/api-action-factory';

import { hashParams, isDev, isEmptyObject } from '../../utils/common';
import { API_CALL_CACHE_DURATION, EMPTY_DATA_HASH } from '../../constants';

import RecruitmentDataModel from './models/recruitment-data-model';
import SiteModelWrapper from './models/site-model';
import PatientsPerSiteModelWrapper from './models/patients-per-site-model';
import WindowDatesModelWrapper from './models/window-dates-model';
import PublicationModelWrapper from './models/publication-model';
import CoordinatingCenterModelWrapper from './models/coordinating-center-model';
import VisibleLocationModel from './models/visible-location-model';
import ReportCardAlertModel from './models/report-card-alert-model';

import {
  SITE_MAPS_ENDPOINT_V2,
  RECRUITMENT_DATA_ENDPOINT,
  PATIENTS_PER_SITE_DATA_ENDPOINT,
  ENROLLMENT_WINDOW_DATE_DATA_ENDPOINT,
  PUBLICATION_LIST_ENDPOINT,
  COORDINATING_CENTERS_ENDPOINT,
} from '../../api/endpoints';

const ReportsStore = StoreFactory({
  modelName: 'ReportsStore',
  models: [
    ApiActionFactory({
      loadEnrollmentWindowDates: {
        endpoint: ENROLLMENT_WINDOW_DATE_DATA_ENDPOINT,
        target: 'enrollmentWindowDates',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          self.enrollmentWindowDatesSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentWindowDatesHash(searchHash);
          self.isEnrollmentWindowDatesDataLoading = false;
        },
        onError(self) {
          self.isEnrollmentWindowDatesDataLoading = false;
        },
        onTrigger(self) {
          self.isEnrollmentWindowDatesDataLoading = true;
        }
      },
      loadSiteMaps: {
        endpoint: SITE_MAPS_ENDPOINT_V2,
        target: 'siteMaps',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          self.siteSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentReportHash(searchHash);
          self.isSiteMapLoading = false;
        },
        onError(self) {
          self.isSiteMapLoading = false;
        },
        onTrigger(self) {
          self.isSiteMapLoading = true;
        }
      },
      loadRecruitmentData: {
        endpoint: RECRUITMENT_DATA_ENDPOINT,
        target: 'recruitmentData',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          const recruitmentData = self.recruitmentData.get(searchHash);

          const newRecruitmentData = randomizeRecruitmentDataColors(recruitmentData);

          applySnapshot(recruitmentData, {
            data: newRecruitmentData,
          });

          // Add total recruitment data
          if (recruitmentData.data.length > 1) {
            const bigTotal = reduce(recruitmentData.data, (res, val) => (
              isEmptyObject(res) ? { ...val, color: '#000066' } : {
                ...res,
                dataPoints: map(val.dataPoints, (val, ind) => ({
                  ...val,
                  NewPatients: res.dataPoints[ind]?.NewPatients + val?.NewPatients,
                  TotalPatients: res.dataPoints[ind]?.TotalPatients + val?.TotalPatients,
                }))
              }
            ), {});
      
            recruitmentData.data.push(bigTotal);
          }
          // Change color of the only one recruitment data
          else if (recruitmentData.data.length === 1) {
            recruitmentData.data[0].color = '#000066';
          }

          self.recruitmentSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentReportHash(searchHash);
          self.isRecruitmentDataLoading = false;
        },
        onError(self) {
          self.isRecruitmentDataLoading = false;
        },
        onTrigger(self) {
          self.isRecruitmentDataLoading = true;
        }
      },
      loadPatientsPerSiteData: {
        endpoint: PATIENTS_PER_SITE_DATA_ENDPOINT,
        target: 'patientsPerSiteData',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          self.patientsPerSiteSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentReportHash(searchHash);
          self.isPatientsPerSiteDataLoading = false;
        },
        onError(self) {
          self.isPatientsPerSiteDataLoading = false;
        },
        onTrigger(self) {
          self.isPatientsPerSiteDataLoading = true;
        }
      },
      loadPublicationList: {
        endpoint: PUBLICATION_LIST_ENDPOINT,
        target: 'publicationList',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          self.publicationListSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentReportHash(searchHash);
          self.isPublicationListLoading = false;
        },
        onError(self) {
          self.isPublicationListLoading = false;
        },
        onTrigger(self) {
          self.isPublicationListLoading = true;
        }
      },
      loadCoordinatingCenters: {
        endpoint: COORDINATING_CENTERS_ENDPOINT,
        target: 'coordinatingCenters',
        targetObjectId: 'searchHash',
        onLoad(self, searchHash) {
          self.coordinatingCentersSearchHashes.set(searchHash, Date.now() + API_CALL_CACHE_DURATION);
          self.setCurrentCoordinatingCentersHash(searchHash);
          self.isCoordinatingCentersLoading = false;
        },
        onError(self) {
          self.isCoordinatingCentersLoading = false;
        },
        onTrigger(self) {
          self.isCoordinatingCentersLoading = true;
        }
      }
    }),
  ],
  modelStructure: {
    currentReportHash: 0,
    currentCoordinatingCentersHash: 0,
    currentWindowDatesHash: 0,
    siteMaps: types.map(SiteModelWrapper),
    siteMapSearchedId: types.maybeNull(types.string),
    siteSearchHashes: types.map(types.number),                              // key: searchHash, value: expiration
    recruitmentData: types.map(types.maybeNull(RecruitmentDataModel)),
    recruitmentSearchHashes: types.map(types.number),                       // key: searchHash, value: expiration
    patientsPerSiteData: types.map(types.maybeNull(PatientsPerSiteModelWrapper)),
    patientsPerSiteSearchHashes: types.map(types.number),                   // key: searchHash, value: expiration
    enrollmentWindowDates: types.map(WindowDatesModelWrapper),
    enrollmentWindowDatesSearchHashes: types.map(types.number),             // key: searchHash, value: expiration
    publicationList: types.map(PublicationModelWrapper),
    publicationListSearchHashes: types.map(types.number),                  // key: searchHash, value: expiration
    coordinatingCenters: types.map(CoordinatingCenterModelWrapper),
    coordinatingCentersSearchHashes: types.map(types.number),              // key: searchHash, value: expiration
    visibleLocations: types.maybeNull(types.array(VisibleLocationModel)),
    canadaOnly: true,
    screenshot: types.frozen(),
    isLoading: false,
    isSiteMapLoading: false,
    isRecruitmentDataLoading: false,
    isPatientsPerSiteDataLoading: false,
    isEnrollmentWindowDatesDataLoading: false,
    isPublicationListLoading: false,
    isCoordinatingCentersLoading: false,
    lastCallTime: 0,
    applyFiltersNow: false,
    selectedEnrollmentWindowDates: types.array(types.number),
    reportCardAlert: types.maybeNull(ReportCardAlertModel),
    title: 'ReportsStore',
  },
})
  .actions(self => ({
    afterCreate() {
      self.recruitmentData.set(EMPTY_DATA_HASH, {
        data: []
      });

      self.patientsPerSiteData.set(EMPTY_DATA_HASH, {
        Item: {
          SiteNumbers: [],
          Stats: {}
        }
      });

      self.siteMaps.set(EMPTY_DATA_HASH, {
        Item: []
      });

      self.coordinatingCenters.set(EMPTY_DATA_HASH, {
        Item: []
      });

      self.enrollmentWindowDates.set(EMPTY_DATA_HASH, {
        Item: null
      });

      self.publicationList.set(EMPTY_DATA_HASH, {
        Item: []
      });
    },
    retrieveSiteMaps(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.siteSearchHashes.has(searchHash);
      const hashIsExpired = self.siteSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentReportHash(searchHash);
        return;
      }
      
      self.loadSiteMaps({...reportRequest, searchHash: searchHash});
    },
    retrievePatientsPerSiteData(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.patientsPerSiteSearchHashes.has(searchHash);
      const hashIsExpired = self.patientsPerSiteSearchHashes.get(searchHash) < Date.now();
      
      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentReportHash(searchHash);
        return;
      }

      self.loadPatientsPerSiteData({...reportRequest, searchHash: searchHash});
    },
    retrieveRecruitmentData(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.recruitmentSearchHashes.has(searchHash);
      const hashIsExpired = self.recruitmentSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentReportHash(searchHash);
        return;
      }

      self.loadRecruitmentData({...reportRequest, searchHash: searchHash});
    },
    async retrieveEnrollmentWindowDates(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.enrollmentWindowDatesSearchHashes.has(searchHash);
      const hashIsExpired = self.enrollmentWindowDatesSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentWindowDatesHash(searchHash);
        return self.getEnrollmentWindowDates(searchHash);
      }

      await self.loadEnrollmentWindowDates({...reportRequest, searchHash: searchHash});
      return self.getEnrollmentWindowDates(searchHash);
    },
    retrievePublicationList(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.publicationListSearchHashes.has(searchHash);
      const hashIsExpired = self.publicationListSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentReportHash(searchHash);
        return;
      }

      self.loadPublicationList({...reportRequest, searchHash: searchHash});
    },
    retrieveCoordinatingCenters(reportRequest) {
      const searchHash = hashParams(reportRequest);
      const hashAlreadyExists = self.coordinatingCentersSearchHashes.has(searchHash);
      const hashIsExpired = self.coordinatingCentersSearchHashes.get(searchHash) < Date.now();

      if (!hashIsExpired && (hashAlreadyExists || searchHash === 0)) {
        self.setCurrentCoordinatingCentersHash(searchHash);
        return;
      }

      self.loadCoordinatingCenters({...reportRequest, searchHash: searchHash});
    },
    setSiteMapSearchedId(id) {
      self.siteMapSearchedId = id;
    },
    setCanadaOnly(canadaOnly) {
      self.canadaOnly = canadaOnly;
    },
    setScreenshot(screenshot) {
      self.screenshot = screenshot;
    },
    resetScreenshot() {
      self.screenshot = null;
    },
    setLastCallTime(time) {
      self.lastCallTime = time;
    },
    setApplyFiltersNow(value) {
      self.applyFiltersNow = value;
    },
    setCurrentReportHash(hash) {
      self.currentReportHash = hash;
    },
    resetCurrentReportHash() {
      self.currentReportHash = 0;
    },
    setCurrentCoordinatingCentersHash(hash) {
      self.currentCoordinatingCentersHash = hash;
    },
    clearRecruitmentData() {
      self.currentReportHash = EMPTY_DATA_HASH;
    },
    clearPatientsPerSiteData() {
      self.currentReportHash = EMPTY_DATA_HASH;
    },
    clearSiteMapsData() {
      self.currentReportHash = EMPTY_DATA_HASH;
    },
    clearCoordinatingCenters() {
      self.currentReportHash = EMPTY_DATA_HASH;
    },
    setSelectedEnrollmentWindowDates(enrollmentWindow) {
      self.selectedEnrollmentWindowDates = enrollmentWindow;
    },
    setCurrentWindowDatesHash(hash) {
      self.currentWindowDatesHash = hash;
    },
    resetCurrentWindowDatesHash() {
      self.currentWindowDatesHash = 0;
    },
    setVisibleLocations(locations) {
      self.visibleLocations = locations;
    },
    setReportCardAlert(alert) {
      self.reportCardAlert = alert;
    },
  }))
  .views(self => ({
    getSiteMap(searchHash) {
      return self.siteMaps.get(searchHash)?.Item;
    },
    getSiteMapSearchedId() {
      return self.siteMapSearchedId;
    },
    getRecruitmentData(searchHash) {
      return self.recruitmentData.get(searchHash);
    },
    getPatientsPerSiteData(searchHash) {
      return self.patientsPerSiteData.get(searchHash)?.Item;
    },
    getEnrollmentWindowDates(searchHash) {
      return self.enrollmentWindowDates.get(searchHash)?.Item;
    },
    getPublicationList(searchHash) {
      return self.publicationList.get(searchHash)?.Item;
    },
    getCoordinatingCenters(searchHash) {
      return self.coordinatingCenters.get(searchHash)?.Item;
    },
    getVisibleLocations() {
      return self.visibleLocations;
    },
    get isDataLoading() {
      return self.isSiteMapLoading ||
             self.isRecruitmentDataLoading ||
             self.isPatientsPerSiteDataLoading ||
             self.isPublicationListLoading ||
             self.isCoordinatingCentersLoading;
    }
  }));
  

if (window && isDev()) {
  window.ReportsStore = ReportsStore || {};
}

export default ReportsStore;

const randomizeRecruitmentDataColors = (recruitmentData) => {
  const recruitmentDataColors = map(recruitmentData.data, 'color');

  return map(recruitmentData.data, (rh) => {
    const color = sample(recruitmentDataColors);

    // Remove color from the list of available colors
    recruitmentDataColors.splice(recruitmentDataColors.indexOf(color), 1);

    return {
      color,
      dataPoints: map(rh.dataPoints, i => ({
        ...i,
      })),
    };
  });
}

