export const ROUTES = {
  RESEARCH_PORTFOLIO: {
    id: 'RESEARCH_PORTFOLIO',
    name: 'Research portfolio',
    i18nId: 'research_portfolio:menu-label',
    displayInMenu: false,
  },
};

export const { RESEARCH_PORTFOLIO: DEFAULT_ROUTE } = ROUTES;
export default ROUTES;
