import { isDev } from './utils/common';

export const APP_NAME = isDev() ? '' : '';

export const LOCAL_STORAGE_KEY = 'ccrn_feature_flags';
export const API_CALL_TIMEOUT = 2000;
export const API_CALL_CACHE_DURATION = 1000 * 60 * 30; // 30 minutes
export const SUSPENSE_FADE_DURATION = 1250;
export const HELP_VIDEOS_URL = 'https://www.youtube.com/playlist?list=PLbZR8BAnMVpmFbGsh2njOH6WlLgESq1o-';
export const EMPTY_DATA_HASH = -1;    // Report hash for empty data
export const MENU_FADE_DURATION = 250;
export const SITE_PROJECT_ID = 1;
export const TWO_FACTOR_CODE_LENGTH = 6;
export const USERNAME_MIN_LENGTH = 2; // Cannot be less than 2
export const USERNAME_MAX_LENGTH = 32;
export const INFO_EMAIL_ADDRESS = 'info@ccrn-rrcc.ca';
export const INFO_EMAIL_LINK = `mailto:${ INFO_EMAIL_ADDRESS }`;

export default APP_NAME;
