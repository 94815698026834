import { types } from 'mobx-state-tree';

import { API_CALL_CACHE_DURATION } from '../../constants';

const CallCacheModel = types.optional(
  types
    .model({
      cache: types.map(
        types.model({
          lastCalled: types.optional(types.Date, new Date()),
        })
      ),
    })
    .actions((self) => ({
      /**
       * Check if the provided key needs to be refreshed. Duration of valid cache can be customized
       * @param {string} key Key of the call to validate
       * @param {number?} duration Duration of valid cache (Default 30 minutes)
       * @returns Whether the provided key needs to be refreshed
       */
      mustRefresh(key, duration) {
        return (
          !self.cache.has(key) ||
          new Date() - self.cache.get(key)?.lastCalled >
            (duration ?? API_CALL_CACHE_DURATION)
        );
      },
      /**
       * Refresh the date of the provided key (this means a new call was made)
       * @param {string} key Key of the call
       */
      refreshDate(key) {
        self.cache.set(key, {
          lastCalled: new Date(),
        });
      },
      reset() {
        self.cache.clear();
      }
    })),
  {}
);

export default CallCacheModel;
