/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';

import StoreFactory from '../factories/store';

const CcrnStore = StoreFactory({
  modelName: 'CcrnStore',
  models: [],
  modelStructure: {
    isLoading: false,
    title: 'CcrnStore',
  },
});

if (window && isDev()) {
  window.CcrnStore = CcrnStore || {};
}

export default CcrnStore;
