/* eslint-disable no-param-reassign */
import { isDev } from '../../utils/common';

import StoreFactory from '../factories/store';

const PublicStore = StoreFactory({
  modelName: 'PublicStore',
  models: [],
  modelStructure: {
    isLoading: false,
    title: 'PublicStore',
  },
});

if (window && isDev()) {
  window.PublicStore = PublicStore || {};
}

export default PublicStore;
