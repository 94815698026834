import { types } from "mobx-state-tree";
import ApiResponse from "../../models/api-response-model";

const AuthExpirationModel = ApiResponse.named("AuthExpirationModel").props({
  Item: types.maybeNull(types.model({
    authExpiration: types.string,
  })),
});

export default AuthExpirationModel;
