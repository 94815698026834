import { types } from 'mobx-state-tree';

const TextValueModel = types.model(
    'TextValueModel',
    {
        Text: "",
        Value: "",
    }
);

export default TextValueModel;
